<template>
  <div v-if="getVisibleVirtualVisit">
    <div class="notification-popup">
      <div class="notification-popup-content">
        <div class="close" @click="closePopup('XButtonClose')" />
        <div @click="goToVirtualVisitUrl">
          <img :src="getVirtualVisitImage" alt="notification icon" />
          <p class="text top">Sana özel hazırladığım ziyaret yenilendi</p>
          <p class="text bottom">Şef Dükkanında</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import gtmUtils from '@/mixins/gtmUtils.js';
export default {
  name: 'VirtualVisitPopup',
  mixins: [gtmUtils],
  computed: {
    ...mapGetters('app', ['getPopups']),
    getVisibleVirtualVisit() {
      return this.getPopups.popupId == 3 && this.$route.fullPath == '/secure/feed';
    },
    getVirtualVisitImage() {
      return this.getPopups?.popupDetail?.image ?? '';
    },
  },
  methods: {
    setEventData(isClick) {
      return {
        event: 'cEvent',
        category: 'Pop Up',
        action: isClick ? 'Click' : 'Close',
        label: 'VirtualVisit',
        value: 0,
        isNonInteraction: false,
      };
    },
    closePopup(action) {
      const eventData = this.setEventData(false);
      this.$emit('closePopup', eventData, { type: action });
    },
    goToVirtualVisitUrl() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('sef_dukkaninda', {
        location: 'pop_up',
      });
      this.$router.push(`${this.getPopups.popupDetail.url}`);

      const eventData = this.setEventData(true);
      this.$emit('closePopup', eventData, { type: 'LinkClick' });
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-popup {
  position: absolute;
  top: 0;
  right: -5px;
  left: -5px;
  bottom: 0;
  z-index: -1;
  background-color: #fff;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &-content {
    position: absolute;
    top: -300px;
    right: -40px;
    width: 323px;
    .text {
      position: absolute;
      left: 20px;
      font-weight: 500;
      &.top {
        font-size: 16px;
        bottom: 78px;
        color: #ffbfbf;
      }
      &.bottom {
        font-size: 24px;
        bottom: 45px;
        color: #fff;
      }
    }
    .close {
      position: absolute;
      padding: 5px;
      left: 0;
      top: 0;
      color: #fff;
      width: 45px;
      height: 45px;
      cursor: pointer;
      transition: opacity 0.2s ease;
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 20px;
        left: 15px;
        width: 16px;
        height: 2px;
        background-color: #fff;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(135deg);
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>
